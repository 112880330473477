import { graphql, Link } from 'gatsby';
import React from 'react';

import Layout from '../components/Layout';

// eslint-disable-next-line react/prop-types
const List = ({ data }) => {
  // const siteTitle = data.site.siteMetadata?.title || `Title`;

  // eslint-disable-next-line react/prop-types
  const posts = data.allMarkdownRemark.nodes as [];

  // const siteTitle = data.site.siteMetadata?.title || `Title`;
  // const posts = data.allMarkdownRemark.nodes;
  // if (posts.length === 0) return <>no contents</>;

  return (
    <Layout>
      <ol style={{ listStyle: `none` }}>
        {posts.map((p) => {
          const post = p as any;
          const title = post.frontmatter.title || post.fields.slug;

          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{post.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
          );
        })}
      </ol>
    </Layout>
  );
};
export default List;

// /**
//  * Head export to define metadata for the page
//  *
//  * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
//  */
// export const Head = () => (
//   <Seo title="All posts" children={undefined} lang={undefined} />
// );

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`;
